import IconWellContainer from "@/components/help-center/custom-tags/IconWellContainer.vue";

export const iconWellContainer = {
  render: IconWellContainer,
  attributes: {
    iconName: {
      type: String,
      default: "info",
      required: true,
    },
    iconBackgroundColor: {
      type: String,
    },
  },
};
