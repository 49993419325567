<template>
  <div class="overflow-hidden rounded-lg bg-gray-100">
    <div class="p-2 flex items-center space-x-4">
      <div
        :class="iconBackgroundColor"
        class="flex items-center h-30 w-30 p-4 rounded-lg"
      >
        <component :is="icon" class="h-24 w-24 text-white" aria-hidden="true" />
      </div>
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  InformationCircleIcon,
  RocketLaunchIcon,
} from "@heroicons/vue/24/outline";
import { computed } from "vue";

const props = defineProps(["iconName", "iconBackgroundColor"]);

const icon = computed(() => {
  switch (props.iconName) {
    case "rocket":
      return RocketLaunchIcon;
    default:
      return InformationCircleIcon;
  }
});
</script>
